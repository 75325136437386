import React from 'react';
import './App.css';
import SignIn from './SignIn';

function App() {
    return (
        <div className='App'>
            <SignIn />
        </div>
    );
}

export default App;
